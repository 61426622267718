import React from "react";
import { graphql } from "gatsby";
import HomeScreen from "../components/evento/HomeScreen";
import Layout from "../components/layout";
import SEO from "../components/seo";

const HomePage = ({ location, data }) => {
  const areasTematicas = data.allStrapiAgendaMasterAreaTematica.edges.map(
    (edge) => edge.node
  );
  const tiposEvento = data.allStrapiAgendaMasterTipoEvento.edges.map(
    (edge) => edge.node
  );
  const eventos = data.allApiExternaEventosNext.edges.map((edge) => edge.node);

  const resultData = {
    next: eventos,
    areasTematicas: areasTematicas,
    tipoEvento: tiposEvento,
    variablesEntorno: {
      AGENDA_APP_SITE: data.site.siteMetadata.AGENDA_APP_SITE,
    },
    location: location
  };

  return (
    <Layout>
      <SEO title="PUCP" pathname="/home" />
      <HomeScreen resultData={resultData} />
    </Layout>
  );
};
export default HomePage;

export const pageQuery = graphql`
  query HomeQuery {
    allStrapiAgendaMasterAreaTematica {
      edges {
        node {
          Nombre
          id
          strapiId
        }
      }
    }
    allStrapiAgendaMasterTipoEvento {
      edges {
        node {
          Nombre
          TipoEvento
          id
          strapiId
        }
      }
    }
    site {
      siteMetadata {
        title
        AGENDA_APP_SITE
      }
    }
    allApiExternaEventosNext(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          slug
          EventoDestacado
          ImagenDestacada {
            alternativeText
            alternative_id
            ext
            name
            mime
            height
            provider
            size
            url
            width
          }
          Titulo
          Fechas {
            Fecha
            Fin
            Inicio
            _xcomponent
            alternative_id
          }
          WorkFlow
          alternative_id
          area_tematicas {
            Nombre
            alternative_id
          }
          tipo_evento {
            Nombre
            TipoEvento
            alternative_id
          }
          id
          Lugar {
            DescripcionLink
            DescripcionLugar
            URL
            Ubicacion
            agenda_master_lugar_pucp {
              Nombre
              alternative_id
            }
            alternative_id
            _xcomponent
          }
          agenda_master_etiquetas {
            Nombre
            alternative_id
          }
        }
      }
    }
  }
`;
